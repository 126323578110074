<template>
  <main-canvas
    v-if="size"
    :width="size.width"
    :height="size.height"
    v-resize="onResize"
  />
  <!--v-layout wrap>
    <v-flex xs8 style="padding: 10px;">
    <v-card id="main-seats-map">
      <v-responsive :aspect-ratio="16/9">
        <v-card-text style="padding: 0;">
          {{size}}
          <main-canvas
            v-if="size"
            :width="size.width"
            :height="size.height"
          />
        </v-card-text>
      </v-responsive>
    </v-card>
    </v-flex>
    <v-flex xs4>
    </v-flex>
  </v-layout-->
</template>
<script>
//import smService from '@/services/seatsMap'
import MainCanvas from './MainCanvas'
export default {
  components: {
    MainCanvas,
  },
  data: () => ({
    size: { width: 100, height: 100 },
  }),
  mounted () {      
    this.onResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize()) 
  },
  /*beforeDestroy() { 
    window.removeEventListener('resize', this.handleResize()) 
  },*/
  methods: {
    handleResize () {
      console.log('aaa')
    },
    /*onResize (w) {
      this.size = smService.prepareMainSize(w)
      console.log(this.size.width)
    },*/
    onResize () {
      this.size = { width: window.innerWidth, height: window.innerHeight }
    },
  },
}
</script>

